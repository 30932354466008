// Dependencies
import React from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
// import Image from "../components/Image"
import RichText from "../components/RichText"

const PageTemplate = ({ data, pageContext }) => {

	const { title, content, style } = data.contentfulPage

	const className = style && style === 'legal' ? `text-columns has-links legal p2` : ``
	
  return (
    <Layout title={title}>
			<section className="grid innerx4 guttersx4">
				<div className={`span-6 start-7 ${className}`}>
					<RichText content={content} />
				</div>
			</section>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const PageTemplatePageQuery = graphql`
  query PageTemplateQuery($slug: String!) {
    contentfulPage(slug: {eq: $slug}) {
      title
			style
			content {
        raw
				references {
					... on ContentfulAsset {
            __typename
            contentful_id
						gatsbyImageData(
							layout: FULL_WIDTH
						)
          }
          ... on ContentfulStory {
            __typename
            contentful_id
						image {
							gatsbyImageData(
								layout: FULL_WIDTH
							)
						}
          }
          ... on ContentfulVideo {
            __typename
            contentful_id
						vimeoUrl
						coverImage {
							gatsbyImageData(
								layout: FULL_WIDTH
							)
						}
          }
				}
      }
    }
  }
`
